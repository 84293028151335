<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <!-- <div>
          <router-link :to="'/zayavkadetail/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
            </div>
          </router-link>
        </div> -->
        <div>
          <router-link :to="'/newzayavkadetail/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
            </div>
          </router-link>
        </div>
        <router-link
          :to="'/zayavkaedit/' + index.id"
          class="navi-link"
          v-if="index.zayavka_status !== 'DEL'"
        >
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>
        <div></div>
        <template>
          <div class="card__action">
            <template v-if="index.zayavka_status != 'DEL'">
              <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="navi-link w-100" v-bind="attrs" v-on="on">
                    <span class="navi-icon">
                      <i class="flaticon2-file-1 text-primary"></i>
                    </span>
                    <span class="navi-text">{{
                      $t('ACTION.CHANGE_STATUS')
                    }}</span>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Statusni o'zgartirish 2</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="select"
                            :hint="`${selected.state}, ${selected.abbr}`"
                            :items="items"
                            item-text="state"
                            item-value="abbr"
                            label="Status"
                            return-object
                            no-data-text="Yuk Yetkazilgan"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                      >Yopish</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      :disabled="index.zayavka_status == 'DEL'"
                      text
                      @click="update"
                      >Saqlash</v-btn
                    >
                    <v-dialog
                      v-model="confirmModal"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                    >
                      <v-card>
                        <v-container fluid>
                          <v-row justify="center">
                            <v-col cols="12" md="6">
                              <div class="card card-custom container">
                                <div class="card-header px-0">
                                  <div class="card-title">
                                    <h3 class="card-label">Izoh</h3>
                                  </div>
                                </div>
                                <!--begin::Form-->
                                <v-textarea
                                  v-model="comments"
                                  dense
                                  name="input-7-4"
                                  outlined
                                  placeholder="Izoh qoldiring"
                                ></v-textarea>
                                <!-- <v-dialog
                                  ref="dialog"
                                  v-model="modal"
                                  :return-value.sync="delivered_date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }"> -->
                                <v-text-field
                                  :value="delivered_date | dataFilter"
                                  label="Yetkazib berilgan sana"
                                  prepend-inner-icon="mdi-calendar"
                                  v-mask="'##-##-####'"
                                  @input="
                                    onCustomDateInput(
                                      'delivered_date',
                                      arguments[0]
                                    )
                                  "
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                                <!-- </template>
                                  <v-date-picker
                                    v-model="delivered_date"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(delivered_date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog> -->
                                <v-text-field
                                  dense
                                  outlined
                                  v-mask="'##########'"
                                  v-model="zayavka_distance"
                                  label="Yurilgan km"
                                ></v-text-field>

                                <v-file-input
                                  label="Rasm joylash"
                                  outlined
                                  :state="Boolean(files)"
                                  multiple
                                  v-model="files"
                                  dense
                                ></v-file-input>

                                <v-btn
                                  color="error"
                                  class="my-2"
                                  @click="confirmModal = false"
                                  >Bekor Qilish</v-btn
                                >
                                <v-btn color="primary" @click="save"
                                  >Saqlash</v-btn
                                >
                                <!--end::Form-->
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-else-if="index.zayavka_status == 'DEL'">
              <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="navi-link w-100" v-bind="attrs" v-on="on">
                    <span class="navi-icon">
                      <i class="flaticon2-file-1 text-primary"></i>
                    </span>
                    <span class="navi-text"
                      >Buyurtma masofasini o'zgartirish</span
                    >
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline"
                      >Buyurtma masofasini o'zgartirish</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <!-- <v-container> -->
                    <v-row>
                      <v-col>
                        <div class="h4 mt-5 font-weight-bold">
                          Hozirgi masofa: {{ index.zayavka_distance || 0 }} km
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          class="m-0 p-0"
                          @keypress="isNumberKey"
                          v-model="newDistance"
                          label="Buyurtma masofasi"
                          placeholder="Yangi buyurtma masofasini kiriting"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- </v-container> -->
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                      >Yopish</v-btn
                    >
                    <v-btn
                      color="primary"
                      elevation="0"
                      @click="updateZayavkaDistance"
                      >Saqlash</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <!-- buyurtma masofasini o'zgartirish -->
            <!-- input km -->
          </div>
        </template>
        <a @click="deleteItem(index.id)" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-delete-1 text-danger"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DELETE') }}</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { isNumberKey } from '@/utils'
import { mapActions } from 'vuex'

export default {
  components: {},
  name: 'ZayavkaAction',
  data: () => ({
    delivered_date: '',
    zayavka_distance: '',
    files: [],
    comments: '',
    confirmModal: false,
    dialog: false,
    modal: false,
    deliveredDate: '',
    select: { state: '', abbr: '' },
    newDistance: null
  }),
  props: {
    btnClass: String,
    index: Object,
    id: Number,
    page: Number
  },
  computed: {
    selected() {
      const data = {
        state: this.index.zayavka_status_name,
        abbr: this.index.zayavka_status
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.select = data
      return data
    },
    items() {
      let data
      if (this.selected.state == 'Buyurtmachida') {
        data = [
          { state: 'Yetqazib berilgan', abbr: 'DEL' },
          { state: "Yo'lda", abbr: 'OTW' }
        ]
      } else if (this.selected.state == "Yo'lda") {
        data = [{ state: 'Yetqazib berilgan', abbr: 'DEL' }]
      } else if (this.selected.state == 'Yetqazib berilgan') {
        data = []
      }
      return data
    }
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.newDistance = null
      }
    }
  },
  methods: {
    isNumberKey,

    ...mapActions(['setZayavkaItem']),

    onCustomDateInput(key, arg) {
      // console.log('onCustomDateInput', key, arg)
      if (arg.length >= 10) {
        let [day, month, year] = arg.split('-')

        this[key] = `${year}-${+month <= 12 ? month : '01'}-${day}`
      } else if (~arg.indexOf(':')) {
        this[key] = arg
      }
    },

    async updateZayavkaDistance() {
      if (!this.newDistance) {
        Swal.fire({
          // title: 'Formada xatolik bor',
          title: 'Yangi masofani kiriting',
          icon: 'error'
        })
        return
      }
      try {
        await this.setZayavkaItem({
          zayavkaItemId: this.index.id,
          data: {
            zayavka_distance: this.newDistance
          }
        })
        Swal.fire({
          // title: 'Formada xatolik bor',
          title: "Yangi masofa muvaffaqiyatli o'zgardi",
          icon: 'success'
        })

        this.$emit('reload')
      } catch (err) {
        //
        this.dialog = false
        Swal.fire({
          // title: 'Formada xatolik bor',
          title: "Yangi masofa o'zgartirishda xato",
          icon: 'error'
        })
      }

      this.dialog = false
    },

    deleteItem(val) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Ochirildi!', '', 'success')
          this.$store.dispatch('deleteZayavka', {
            id: val,
            page: this.$route.params.id
          })
        }
      })
    },
    asd() {
      this.files.forEach((element) => {
        let formData = new FormData()
        formData.append('foto_report', element)
        formData.append('zayavka', this.index.id)
        axios.post('zayavkas/photoreport/', formData).then(() => {})
      })
    },
    save() {
      if (
        this.files.length !== 0 &&
        this.comments !== '' &&
        this.delivered_date !== '' &&
        Boolean(this.zayavka_distance)
      ) {
        this.files.forEach((element) => {
          let formData = new FormData()
          formData.append('foto_report', element)
          formData.append('zayavka', this.index.id)
          axios.post('zayavkas/photoreport/', formData).then(() => {})
        })
        const data = {
          zayavka_distance: parseInt(this.zayavka_distance, 10),
          zayavka_status: 'DEL',
          driver_delivered_comment: this.comments,
          delivered_date: this.delivered_date + 'T00:00:00'
        }
        this.$store.dispatch('confirmZayavkaStatus', {
          id: this.index.id,
          data1: data,
          page: this.$route.params.id
        })
        this.comments = ''
        this.files = ''
        this.confirmModal = false
        this.dialog = false
      } else {
        Swal.fire({
          title: '',
          text: 'Formada xatolik bor',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },
    async update() {
      const data = {
        zayavka_status: this.select.abbr
      }
      if (this.select.abbr !== 'DEL') {
        await this.$store.dispatch('no_reload_updateZayavkaStatusLocally', {
          id: this.index.id,
          page: this.$route.params.id,
          data: data
        })
        this.dialog = false
      } else {
        this.confirmModal = true
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
.image-container {
  width: 100%;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
